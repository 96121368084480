<template>
  <div class="certification container">
    <div class="flex alc jcsb mb20">
      <div>
        共
        <span class="highlight">{{ list.length }}</span>
        个资质
      </div>

      <div class="tag_style cursorP" @click="toCompanyDetail">查看企业详情</div>
    </div>

    <el-collapse v-model="activeNames">
      <el-collapse-item
        :name="index + 1"
        v-for="(item, index) in list"
        :key="item.id"
      >
        <template slot="title">
          <span class="index">{{ index + 1 }}</span>
          <span>
            {{ item.name }}
          </span>
        </template>

        <el-descriptions title="">
          <el-descriptions-item label="资质证书号">
            {{ item.code }}
          </el-descriptions-item>

          <el-descriptions-item label="发证日期">
            {{ item.startTime }}
          </el-descriptions-item>

          <el-descriptions-item label="发证有效期">
            {{ item.endTime }}
          </el-descriptions-item>

          <el-descriptions-item label="发证机关">
            {{ item.issuer }}
          </el-descriptions-item>
        </el-descriptions>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
/* 资质详情 */
import { searchCertDetail } from "@/api/detailPage.js";

export default {
  data() {
    return {
      activeNames: [1],
      companyId: "",
      list: [],
    };
  },

  methods: {
    /* 获取数据 */
    // 获取资质列表
    async getData() {
      const params = {
        companyId: this.companyId,
      };

      const { data } = await searchCertDetail(params);
      this.list = data;
    },

    /* 操作 */
    // 跳转到企业详情页面
    toCompanyDetail() {
      let id = this.companyId;
      if (id) {
        this.$router.push({
          name: "companyDetails",
          params: { name: "资质等级" },
          query: {
            id,
          },
        });
      } else {
        this.$message.warning("暂无企业信息！");
      }
    },

    /* 初始化 */
    __init__() {
      this.companyId = this.$route.params.id;
      this.getData();
    },
  },

  created() {
    this.__init__();
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.certification {
  padding: 20px;
  background-color: #fff;
}
</style>
